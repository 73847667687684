<template>
  <div>

    <div class="form">
      <el-form :inline="true" label-width="100px" :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="form.productName" clearable placeholder="请输入产品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品类别" prop="productCategory">
              <el-select v-model="form.productCategory" clearable placeholder="请选择产品类别">
                <el-option v-for="item in $store.state.category"
                           :key="item.dictKey"
                           :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品价格" prop="price">
              <el-input v-model="form.price" clearable placeholder="请输入产品价格"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="购买人姓名" prop="buyPersonName">
              <el-input v-model="form.buyPersonName" clearable placeholder="请输入购买人姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="购买人手机号" prop="buyPersonPhone">
              <el-input v-model="form.buyPersonPhone" clearable placeholder="请输入购买人手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="list" style="min-height: calc(100vh - 276px)">
      <div class="operation">
        <el-button @click="Add" size="small" type="primary">添加已卖产品</el-button>
      </div>

      <el-table
          :data="table"
          style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
                :data="scope.row.buyList"
                style="width: 100%">
              <el-table-column
                  prop="buyPerson"
                  align="center"
                  label="购买人姓名">

              </el-table-column>
              <el-table-column
                  prop="buyPhone"
                  align="center"
                  label="购买人手机号">

              </el-table-column>
              <el-table-column
                  prop="cardNo"
                  align="center"
                  label="实体卡号">

              </el-table-column>
              <el-table-column
                  prop="amount"
                  align="center"
                  label="实际购买价格">

              </el-table-column>
              <el-table-column
                  prop="buyDate"
                  align="center"
                  label="购买时间">

              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
            prop="productName"
            align="center"
            label="产品名称">
          <template slot-scope="scope">
            <span class="blue" @click="details(scope.row)" >{{ scope.row.productName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="productCategory"
            align="center"
            label="产品类别">
          <template scope="scope">
            <span>{{ $store.state.category.filter((item) => { return item.dictKey == scope.row.productCategory })[0].dictValue }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="price"
            align="center"
            label="产品价格">
        </el-table-column>
        <el-table-column
            prop="personCount"
            align="center"
            label="销售数量">
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>


  </div>
</template>

<script>
export default {
  name: "Sale",
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 10,
        buyPersonName: null, // 购买人姓名
        buyPersonPhone: null, // 购买人手机号
        price: null, // 产品价格
        productCategory: null, //产品类别
        productName: null, // 产品名称
      },
      table: [],
      total: 0,
    }
  },
  created() {
    this.get_list(this.form);
  },
  methods: {
    get_list(y) {

      this.$get("sale",y)
        .then(res => {
          if(res) {
            console.log(res);

            this.total= res.data.total;
            this.table= res.data.list;

          }
        })

    },
    onSubmit(y) {
      y.pageNum= 1;
      this.get_list(y);
    },
    details(e) {
      this.$router.push({
        path: "/SaleDetails",
        query: {
          id: e.productId
        }
      })
    },
    handleSizeChange(e) {
      // this.form.pageNum= 1;
      this.form.pageSize= e;
      this.get_list(this.form);
    },
    handleCurrentChange(e) {
      this.form.pageNum= e;
      this.get_list(this.form);
    },
    Add() {
      this.$router.push({
        path: "/SaleAdd"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
 @import "../../style/Sale/Sale";
</style>
